<template>
  <section class="card">
    <transition name="modal" mode="in-out">
      <modal v-if="modals.main"
             :title="'Nova requisição de despesa'"
             :errors="$root.errors"
             :params="requisicao"
             :closemodal="closeMainModal"
             @salvar="salvarRequisicao"
             @buscarFornecedor="OnSearch"
             @buscarServico="buscarServico"
             @changeServico="changeServico"
             @change-arquivo="changeArquivo"
             @abrirModalFornecedor="abrirModalFornecedor"
             
             >

        <template  v-slot:body="slotProps">
          <form>
            <input type="hidden" v-model="slotProps.params.requisicao.Id" />
            
            <div class="form-group">
              <label for="Descricao">Serviço</label>
              <v-select v-model="slotProps.params.requisicao.Servico"
                        :filterable="false"
                        @search="(search) => slotProps.clickHandler('buscarServico',search)"
                        :options="slotProps.params.servicos"
                        label="Descricao"
                        @input="(val) => { slotProps.clickHandler('changeServico', val);  }"
                        taggable
                        append-to-body
                        push-tags><span slot="no-options">Busca não encontrada</span></v-select>
            </div>
            <div class="form-group"
            style="max-height: 200px; overflow: auto" v-if="slotProps.params.historico.Data && slotProps.params.historico.Data.length">
                <label for="">Histórico (últ. 10)</label>
                <table class="table table-sm">
                    <thead>
                        <tr>
                            <th>Data</th>
                            <th>Fornecedor</th>
                            <th>Valor</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="req in slotProps.params.historico.Data" :key="req.Id">
                            <td>{{ req.Data }}</td>
                            <td>{{ req.FornecedorDisplay }}</td>
                            <td>{{ req.ValorDisplay }}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th colspan="2">Média</th>
                            <td>{{slotProps.params.historico.MediaDisplay}}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <div class="form-group">
              <label for="Descricao">
                Fornecedor
                <button title="Incluir novo fornecedor"
                        type="button"
                        class="novo btn btn-sm btn-default ml-1"
                        @click="slotProps.clickHandler('abrirModalFornecedor')">
                  <i class="fas fa-plus" style="color: inherit;"></i>
                </button>
              </label>
              <v-select v-model="slotProps.params.requisicao.Fornecedor"
                        :filterable="false"
                        @search="(search) => slotProps.clickHandler('buscarFornecedor',search)"
                        :options="slotProps.params.fornecedor"
                        label="Nome"
                        placeholder="Digite para consultar"
                        append-to-body
                        @input="(val) => { slotProps.params.requisicao.FornecedorId = val.Id }"><span slot="no-options">Busca não encontrada</span></v-select>
            </div>
            <div class="form-row">

              <div class="form-group col-md-4">
                <label for="ClassificacaoServico">Classificação</label>
                <select v-model="slotProps.params.requisicao.ClassificacaoServico"
                        class="form-control form-control-sm">
                  <template v-for="t in [{Id:0,Descricao: 'Mecânica'},{Id:1,Descricao: 'Estética'},{Id:2,Descricao: 'Revisão'},{Id:3,Descricao: 'Outros'}]">
                    <option :value="t.Id" :key="t.Id">
                      {{ t.Descricao }}
                    </option>
                  </template>
                  
                </select>
              </div>
              <div class="form-group col-md-4">
                <label for="ValorPadrao">Valor</label>
                <money v-model="slotProps.params.requisicao.Valor"
                       class="form-control form-control-sm"
                       id="Valor"
                       name="Valor"
                       :disabled="slotProps.params.requisicao.Orcamento"></money>
              </div>
              <div class="form-group col-md-4">
                <label for="TipoRequisicaoId">Tipo</label>
                <select v-model="slotProps.params.requisicao.TipoRequisicaoId"
                        :disabled="selecionado.StatusEstoque == 4"
                        class="form-control form-control-sm"
                        id="TipoRequisicaoId"
                        name="TipoRequisicaoId">
                  <template v-for="t in slotProps.params.tipoRequisicao">
                    <option :disabled="!t.Ativo" :value="t.Id" :key="t.Id">
                      {{ t.Descricao }}
                    </option>
                  </template>
                  
                </select>
              </div>
              <div class="form-group col-md-4">
                <div class="checkbox-custom checkbox-default">
                  <input type="checkbox"
                         value="true"
                         id="orcamento"
                         name="orcamento"
                         v-model="slotProps.params.requisicao.Orcamento"
                         @change="() => slotProps.params.requisicao.Valor = 0">
                  <label for="orcamento" class="">Orçamento </label>

                </div>
              </div>
              <div class="form-group col-md-4">
                <label for="ClassificacaoServico">Departamento</label>
                <select v-model="slotProps.params.requisicao.DepartamentoId"
                        class="form-control form-control-sm">
                  <template v-for="d in configs.departamentos">
                    <option :key="d.Id" :value="d.Id">
                      {{d.Nome}}
                    </option>
                  </template>
                </select>
              </div>
              <div class="form-group col-md-4">
                <label for="files">Foto</label>
                <input type="file"
                       name="files"
                       id="files"
                       @change="slotProps.clickHandler('change-arquivo',$event)"
                       accept="image/*"
                       multiple />
                <br />
                <label class="btn btn-sm btn-default"
                       for="files">
                  <i style="color:inherit" class="fas fa-camera"></i>

                </label>
                <span class="file-description">
                  {{ slotProps.params.Arquivos }}
                </span>
              </div>

            </div>

          </form>
        </template>
        <template v-slot:footer="slotFooterProps">
          <button class="btn btn-sm btn-primary pull-right" @click="slotFooterProps.clickHandler('salvar')">
            Salvar
          </button>
        </template>

      </modal>
    </transition>
    <transition name="modal" mode="in-out">
      <modal v-if="modals.fornecedor"
             :title="'Cadastrar fornecedor'"
             :errors="this.$root.errors"
             :params="requisicao"
             :closemodal="closeFornecedorModal"
             :size="'modal-lg'"
             @salvar="salvarFornecedor">

        <template slot="body">
          <pessoa-partial :params="requisicao.obj">
          </pessoa-partial>
        </template>
        <template v-slot:footer="slotFooterProps">
          <button class="btn btn-sm btn-primary pull-right" @click="slotFooterProps.clickHandler('salvar')">
            Salvar
          </button>
        </template>

      </modal>
    </transition>
    <transition name="modal" mode="in-out">
      <modal v-if="modals.details"
             :title="'Detalhes do veículo '+itemSelecionado[0].Placa"
             :errors="this.$root.errors"
             :closemodal="closeDetailsModal"
             :size="'modal-lg'"
             :params="itemSelecionado[0].Id">

        <template v-slot:body="slotProps">
          <avaliacao-detalhes-partial :params="slotProps.params" :estoqueId="itemSelecionado[0].Id" :avaliacaoId="itemSelecionado[0].AvaliacaoId">

          </avaliacao-detalhes-partial>
        </template>
        <template slot="footer" >

        </template>

      </modal>
    </transition>
    <transition name="modal" mode="in-out">
      <modal v-if="modals.vendido"
             :title="'Digite os dados da venda - '+itemSelecionado[0].Placa"
             :errors="this.$root.errors"
             :closemodal="closeVendidoModal"
             :size="'modal-md'"
             :params="itemSelecionado[0]"
             @salvar="handleButtonVendido">

        <template  v-slot:body="slotProps">
          <form>
            <div class="row">
              <input type="hidden" v-model="selecionado.Id" />
              <div class="form-group col-6">
                <label for="DataVenda">Data Venda</label>
                <input type="date"
                       v-model="slotProps.params.DVenda"
                       class="form-control form-control-sm"
                       id="DataVenda"
                       name="DataVenda"
                       :max="new Date().toISOString().split('T')[0]" />
              </div>
              <div class="form-group col-6">
                <label for="ValorVenda">Valor Venda</label>
                <money v-model="slotProps.params.ValorVenda"
                       class="form-control form-control-sm"
                       id="ValorVenda"
                       name="ValorVenda"></money>
              </div>
            </div>

          </form>
        </template>
        <template v-slot:footer="slotFooterProps">
          <button class="btn btn-sm pull-right btn-primary modal-confirm" @click="slotFooterProps.clickHandler('salvar')">Salvar</button>
        </template>

      </modal>
    </transition>
    <transition name="modal" mode="in-out">
      <modal v-if="modals.enviar"
             :title="'Enviar veículo para fornecedor - '+itemSelecionado[0].Placa"
             :errors="this.$root.errors"
             :closemodal="closeEnviarModal"
             :size="'modal-md'"
             :params="requisicao.requisicaoSelected[0]"
             @salvar="handleButtonEnviar">

        <template v-slot:body="slotProps">
          <form>
            <input type="hidden" v-model="slotProps.params.Id" />
            <div class="form-row">
              <div class="form-group col-md-3">
                <div class="checkbox-custom checkbox-default">
                  <input type="checkbox"
                         value="true"
                         id="imprimir"
                         name="imprimir"
                         v-model="slotProps.params.Imprimir">
                  <label for="imprimir" class="">Imprimir</label>
                </div>
              </div>
              <div class="form-group col-md-9">
                <div class="checkbox-custom checkbox-default">
                  <input type="checkbox"
                         value="true"
                         id="localizacao"
                         name="localizacao"
                         v-model="slotProps.params.AlterarLocalizacao">
                  <label for="localizacao" class="">Alterar localização para "<b>{{ slotProps.params.FornecedorDisplay }}</b>"</label>
                </div>
              </div>
              <div class="form-group col-md-9">
                <div class="checkbox-custom checkbox-default" v-if="slotProps.params.FornecedorEmail">
                  <input type="checkbox"
                         value="true"
                         id="enviaemail"
                         name="enviaemail"
                         v-model="slotProps.params.EnviaEmail">
                  <label for="enviaemail" class="">Enviar e-mail ao fornecedor"<b>{{ slotProps.params.FornecedorEmail }}</b>"</label>
                </div>
                <p v-if="!slotProps.params.FornecedorEmail">Fornecedor sem email cadastrado para envio.</p>
              </div>
            </div>
          </form>
        </template>
        <template v-slot:footer="slotFooterProps">
          <button class="btn btn-sm pull-right btn-primary modal-confirm" @click="slotFooterProps.clickHandler('salvar')">Enviar</button>
        </template>

      </modal>
    </transition>
    <transition name="modal" mode="in-out">
      <modal v-if="modals.avaliacao"
             :title="'Associar avaliação em veículo seminovo'"
             :errors="this.$root.errors"
             :closemodal="closeAvaliacaoModal"
             :size="'modal-md'"
             @salvar="handleButtonAssociar">

        <template slot="body">
          <p>Antes de criar uma despesa, selecione a avaliação da compra deste veículo</p>
          <table class="table table-sm table-hover table-striped table-bordered" v-if="avaliacao.length">
            <thead>
              <tr>
                <th>Cód.</th>
                <th>Data</th>
                <th>Placa</th>
                <th>Descrição</th>
                <th>Cliente</th>
                <th>Despesas</th>
                <th>Avaliado</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <template v-for="item in avaliacao">
                <tr :key="item.Id">
                  <td>
                    {{ item.Codigo }}
                  </td>
                  <td>
                    {{ item.DataAvaliacaoDisplay }}
                  </td>
                  <td>
                    {{ item.Placa }}
                  </td>
                  <td>
                    {{ item.Descricao }}
                  </td>
                  <td>
                    {{ item.ClienteNome }}
                  </td>
                  <td>
                    {{ item.ValorPreparacaoDisplay }}
                  </td>
                  <td>
                    {{ item.ValorAvaliadoDisplay }}
                  </td>
                  <td>
                    <button type="button" class="btn btn-sm btn-warning" @click="handleButtonAssociar(item.Id)">Associar</button>
                  </td>
                </tr>

              </template>
            </tbody>
          </table>
          <p v-if="!avaliacao.length" class="text-danger">Nenhuma avaliação encontrada para o veículo com a placa <b>{{itemSelecionado[0].Placa}}</b></p>
        </template>
        <template slot="footer" >
        </template>

      </modal>
    </transition>
    <transition name="modal" mode="in-out">
      <modal v-if="modals.valor"
             :title="'Digite o valor:'"
             :errors="this.$root.errors"
             :closemodal="closeValorModal"
             :size="'modal-sm'"
             :params="requisicao.requisicaoSelected[0]"
             @salvar="handleButtonValor">

        <template  v-slot:body="slotProps">
          <form>
            <input type="hidden" v-model="slotProps.params.Id" />

            <div class="form-group">
              <label class="col-form-label">Valor</label>

              <money v-model="slotProps.params.Valor"
                     class="form-control form-control-sm"
                     id="Valor"
                     name="Valor"></money>
            </div>
            <div class="form-group">
              <label class="col-form-label">Observação</label>
              <textarea rows="6" v-model="slotProps.params.ObservacaoValor" class="form-control form-control-sm">

                    </textarea>
            </div>
          </form>
        </template>
        <template  v-slot:footer="slotFooterProps">
          <button class="btn btn-sm pull-right btn-primary modal-confirm" @click="slotFooterProps.clickHandler('salvar')">Salvar</button>
        </template>

      </modal>
    </transition>
    <transition name="modal" mode="in-out">

      <modal v-if="modals.solicita"
             :title="'Solicitar aprovação: '+requisicao.requisicaoSelected[0].Descricao + ' ' + requisicao.requisicaoSelected[0].ValorDisplay"
             :errors="this.$root.errors"
             :closemodal="closeSolicitaModal"
             :size="'modal-lg'"
             :params="{requisicao, selected: requisicao.requisicaoSelected[0]}"
             @salvar="handleButtonSolicita">

        <template  v-slot:body="slotProps">
          <table class="table table-sm table-hover table-striped table-bordered">
            <thead>
              <tr>
                <th>Id</th>
                <th>Usuario</th>
                <th>Celular</th>
                <th>E-mail</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <template v-for="item in slotProps.params.requisicao.usuarios">
                <tr :key="item.Id">
                  <td>
                    {{ item.Codigo }}
                  </td>
                  <td>
                    {{ item.Pessoa.Nome }}
                  </td>
                  <td>
                    {{ item.Pessoa.Telefones }}
                  </td>
                  <td>
                    {{ item.Pessoa.Email }}
                  </td>
                  <td>
                    <button type="button" class="btn btn-sm btn-warning" @click="slotProps.clickHandler('salvar', { requisicaoId: slotProps.params.selected.Id, usuarioId: item.Id, $event: $event})">Solicitar</button>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </template>
        <template slot="footer" >
        </template>

      </modal>
    </transition>
    <transition name="modal" mode="in-out">
      <modal v-if="modals.encerra"
             :title="'Execução e encerramento dos serviços'"
             :errors="this.$root.errors"
             :closemodal="closeEncerraModal"
             :size="'modal-lg'"
             :params="{requisicao: requisicao, selected: requisicao.requisicaoSelected[0], estoque: itemSelecionado[0] }"
             @salvar="handleButtonEncerra"
             @executar="handleButtonExecutar"
             @inconsistente="handleButtonInconsistente"
             @change-arquivo="changeArquivo"
             @lancamento="handleButtonLancamento"
             @excluir="handleButtonExcluirLancamento">

        <template v-slot:body="slotProps">
          <form>
            <input type="hidden" v-model="slotProps.params.selected.Id" />

            <h4 class="text-primary">Resumo despesa</h4>
            <div class="form-row">
              <table class="table table-sm">
                <tbody>
                  <tr>
                    <th class="text-right">Veículo: </th>
                    <td class="text-left">{{slotProps.params.estoque.Modelo+' ('+slotProps.params.estoque.Placa+')'}}</td>
                    <th class="text-right">Valor:</th>
                    <td class="text-left">{{ slotProps.params.selected.ValorDisplay }}</td>
                  </tr>
                  <tr>
                    <th class="text-right">Requisição: </th>
                    <td class="text-left">{{ slotProps.params.selected.Id }}</td>
                    <th class="text-right">Total Notas:</th>
                    <td class="text-left">{{ slotProps.params.selected.TotalLancadoDisplay }}</td>
                  </tr>
                  <tr>
                    <th class="text-right">Fornecedor: </th>
                    <td class="text-left">{{ slotProps.params.selected.FornecedorDisplay }}</td>
                    <th class="text-right">Desconto: </th>
                    <td class="text-left">{{ slotProps.params.selected.DescontoDisplay }}</td>
                  </tr>
                  <tr>
                    <th class="text-right">Descrição: </th>
                    <td class="text-left">{{ slotProps.params.selected.Descricao}}</td>
                    <th class="text-right">Valor em aberto: </th>
                    <td class="text-left">{{ slotProps.params.selected.TotalEmAbertoDisplay }}</td>
                  </tr>
                  <tr>
                    <th class="text-right">Departamento: </th>
                    <td class="text-left">{{ slotProps.params.selected.DepartamentoDisplay}}</td>
                    <th class="text-right"></th>
                    <td class="text-left"></td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="form-divider"></div>
            <h4 class="text-primary">
              Notas lançadas
              <button title="Incluir nova nota"
                      type="button"
                      v-if="slotProps.params.selected.StatusRequisicao != 6"
                      class="novo btn btn-sm btn-default ml-1"
                      :disabled="slotProps.params.selected.NotaFiscal.some(n => n.editing)"
                      @click="slotProps.params.selected.NotaFiscal.splice(0,0,{ Arquivos: '',RequisicaoId: slotProps.params.selected.Id, DataCadastro: new Date().toISOString().substr(0,new Date().toISOString().indexOf('T')), editing: true})">
                <i class="fas fa-plus" style="color: inherit;"></i>
              </button>
            </h4>
            <div class="form-row">
              <div class="form-group col-lg-12">
                <table class="table table-sm table-striped table-hover table-nota">
                  <thead>
                    <tr>
                      <th width="80px">Tipo doc.</th>
                      <th width="110px">Nota/OS</th>
                      <th width="70px">Série</th>
                      <th width="130px">Valor</th>
                      <th width="80px">Documento</th>
                      <th width="80px">Lançamento</th>
                      <th width="110px">Arquivo</th>
                      <th width="90px" class="text-center">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="nota in slotProps.params.selected.NotaFiscal">
                      <tr :key="nota.Id">
                        <td>
                          <span v-if="!nota.editing">{{ nota.Tipo }}</span>
                          <select class="form-control form-control-sm"
                                  v-model="nota.Tipo"
                                  @change="() => nota.Serie = nota.Tipo == 'OS' ? 'OS' : ''"
                                  v-if="nota.editing">
                            <option value="NF">NF</option>
                            <option value="OS">OS</option>
                          </select>
                        </td>
                        <td v-if="!nota.editing">{{nota.Numero}}</td>
                        <td v-if="nota.editing">
                          <input type="number" class="form-control form-control-sm" min="0" v-model="nota.Numero" />
                        </td>
                        <td v-if="!nota.editing">{{nota.Serie}}</td>
                        <td v-if="nota.editing">
                          <input type="text" :disabled="nota.Tipo == 'OS'" class="form-control form-control-sm" v-model="nota.Serie" />
                        </td>
                        <td v-if="!nota.editing">{{nota.ValorDisplay}}</td>
                        <td v-if="nota.editing">
                          <money class="form-control form-control-sm" v-model="nota.Valor" />
                        </td>
                        <td v-if="!nota.editing">{{nota.DataDocumentoDisplay}}</td>
                        <td v-if="nota.editing">
                          <input type="date"
                                 class="form-control form-control-sm"
                                 v-model="nota.DataDocumento"
                                 :max="new Date().toISOString().substr(0,new Date().toISOString().indexOf('T'))" />
                        </td>
                        <td v-if="!nota.editing">{{nota.DataCadastroDisplay}}</td>
                        <td v-if="nota.editing">
                          <input type="date" disabled="disabled" class="form-control form-control-sm" v-model="nota.DataCadastro" />
                        </td>
                        <td v-if="!nota.editing && nota.PathPDF"><a class="btn btn-sm btn-default" :href="nota.PathPDF" target="_blank"><i style="color:inherit" class="fas fa-file-pdf"></i></a></td>
                        <td v-if="!nota.editing && !nota.PathPDF">Indisponível</td>
                        <td v-if="nota.editing">
                          <input type="file"
                                 name="files"
                                 id="files"
                                 @change="slotProps.clickHandler('change-arquivo', $event)"
                                 accept=".pdf" />
                          <label class="btn btn-sm btn-default"
                                 for="files">
                            <i style="color:inherit" class="fas fa-upload"></i>

                          </label>
                          <span class="file-description">
                            {{ slotProps.params.requisicao.Arquivos }}
                          </span>
                        </td>
                        <td class="text-center">
                          <button type="button" v-if="nota.editing" title="Salvar" @click="slotProps.clickHandler('lancamento',nota)" class="btn btn-sm btn-primary pull-left"><i class="fas fa-save"></i></button>
                          <button type="button" v-if="nota.editing" @click="slotProps.params.selected.NotaFiscal.splice(slotProps.params.selected.NotaFiscal.indexOf(nota),1)" title="Cancelar" class="btn btn-sm btn-danger pull-right"><i class="fas fa-times"></i></button>
                          <button type="button" v-if="!nota.editing && slotProps.params.selected.StatusRequisicao !== 6" title="Excluir" @click="slotProps.clickHandler('excluir',nota)" class="btn btn-sm btn-danger"><i class="far fa-trash-alt"></i></button>
                        </td>
                      </tr>

                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </form>
        </template>
        <template v-slot:footer="slotFooterProps">
          <button class="btn btn-sm pull-right btn-success modal-confirm" v-if="slotFooterProps.params.selected.StatusRequisicao == 4" @click="slotFooterProps.clickHandler('executar')">Executar</button>
          <button class="btn btn-sm pull-right btn-primary modal-confirm" v-if="slotFooterProps.params.selected.StatusRequisicao == 5" @click="slotFooterProps.clickHandler('salvar')" title="Nota fiscal conferida e as inforamções estão corretas">Encerrar</button>
          <button class="btn btn-sm pull-right btn-warning modal-confirm mr-2" v-if="slotFooterProps.params.selected.StatusRequisicao == 5" @click="slotFooterProps.clickHandler('inconsistente')" title="Nota fiscal não bate com a descrição do serviço">Inconsistente</button>
        </template>

      </modal>
    </transition>

    <data-header :params="requisicao"
                 :handleTrocarVisao="handleTrocarVisao"
                 :filtros="filtros"
                 :itemSelecionado="itemSelecionado"
                 :actionButtons="actionButtons">
      <template v-slot:header="slotHeaderProps">
        <span class="selecionado" :title="slotHeaderProps.itemSelecionado[0].Modelo + ' (' + slotHeaderProps.itemSelecionado[0].Placa+')'">
          <b>{{ slotHeaderProps.itemSelecionado[0].Placa }} - </b><span>{{ slotHeaderProps.itemSelecionado[0].Modelo }}</span>
        </span>
      </template>
      <template slot="desc">
        <div style="padding: 3px 0" class="legenda-status text-center">
          <small class="d-block"><b>Legenda status:</b></small>
          <small class="mr-1"><span class="badge badge-dark">DE</span> = Devolvido</small>
          <small class="mr-1"><span class="badge badge-info">EP</span> = Em preparo</small>
          <small class="mr-1"><span class="badge badge-success">ES</span> = Estoque</small>
          <small class="mr-1"><span class="badge badge-muted">VE</span> = Vendido</small>
        </div>
      </template>
      <template  v-slot:subheader="slotProps">
        <div>
          <small style="font-size: 72%;">Total preparo: <b>R$ {{ slotProps.params.totalEstoque.preparo.toFixed(2).toString().replace('.',',').toString() }}</b></small>
          <small style="font-size: 72%;"> | Total garantia: <b>R$ {{ slotProps.params.totalEstoque.garantia.toFixed(2).toString().replace('.',',').toString() }}</b></small>
        </div>
      </template>
      <template v-slot:buttons="slotButtonsPropos">
        <div class="btn-group" v-if="slotButtonsPropos.params.requisicaoSelected.length">
          <button type="button" class="btn btn-primary dropdown-toggle btn-sm mr-3" data-toggle="dropdown">Despesa <span class="caret"></span></button>
          <div class="dropdown-menu" role="menu">
            <template v-for="btn in actionButtons.filter(b => b.className.includes('requisicao')).sort((a,b) => a.order - b.order)">
              <a
                 :key="btn.id"
                 v-if="btn.visible"
                 :class="btn.className + ' dropdown-item text-1'"
                 href="javascript:;"
                 :title="btn.title"
                 @click="btn.callback">
                <i v-if="btn.icon"
                   :style="{color: btn.iconColor || 'inherit'}"
                   :class="btn.icon"></i> {{btn.text}}
              </a>
            </template>
            
          </div>
        </div>
        <div class="btn-group" v-if="slotButtonsPropos.itemSelecionado.length && slotButtonsPropos.itemSelecionado[0].Id != 0">
          <button type="button" class="btn btn-default dropdown-toggle btn-sm" data-toggle="dropdown">Veículo <span class="caret"></span></button>
          <div class="dropdown-menu" role="menu">
            <template v-for="btn in actionButtons.filter(b => b.className.includes('veiculo'))">
              <a :key="btn.id"
                 v-if="btn.visible"
                 :class="btn.className + ' dropdown-item text-1'"
                 href="javascript:;"
                 :title="btn.title"
                 @click="btn.callback">
                <i v-if="btn.icon"
                   :style="{color: btn.iconColor || 'inherit'}"
                   :class="btn.icon"></i> {{btn.text}}
              </a>
            </template>
            
          </div>
        </div>
      </template>
    </data-header>
    <div class="row main-row">
      <filtro-component :filtros="filtros"
                        :params="params"
                        :handleConsultar="handleConsultar"></filtro-component>
      <data-table :result="result"
                  :params="params"
                  :title="getTitle"
                  :handleRowClick="handleRowClick"
                  :checkboxClass="resolveCheckboxClass"
                  :itemSelecionado="itemSelecionado[0]"
                  :resolveStatusClass="resolveStatusClass"
                  :handleOrdenar="handleOrdenar"
                  :selected="selected"
                  :filtros="filtros"
                  :classTableContainer="(selected.length ? 'half' : '')"
                  :footerContent="footerContent"
                  :handleConsultar="handleConsultar">
        <template slot="table2">
          <div class="table-responsive h-35" v-if="selected.length">
            <table class="table table-bordered table-hover mb-0 table-sm"
                   v-if="result.Records.filter(f=>f.Id == selected[0])[0].Requisicao.length">
              <thead>
                <tr><th colspan="13" class="pl-2" style="background: #f6f6f6">Despesas</th></tr>
                <tr>
                  <th>
                    <div class="checkbox-custom checkbox-primary">
                      <input type="checkbox" checked="" class="check-table">
                    </div>
                  </th>
                  <th>Id</th>
                  <th>Descrição</th>
                  <th>Classif.</th>
                  <th>Tipo</th>
                  <th>Valor</th>
                  <th>Status</th>
                  <th>Cadastro</th>
                  <th>Status</th>
                  <th>Solicitante</th>
                  <th>Aprovador</th>
                  <th>Fornecedor</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(item) in result.Records.filter(f=>f.Id == selected[0])[0].Requisicao">
                  <tr
                      :key="item.Id"
                      @click="handleRowClickRequisicao(item)"
                      :class="(item === requisicao.requisicaoSelected[0] ? 'active' : '') + (item.Inconsistente ? ' warning' : '')"
                      :title="requisicaoTitleHelper(item)"
                      v-if="selected.length">
                    <td>
                      <div class="checkbox-custom checkbox-primary">
                        <input type="checkbox"
                               v-model="requisicao.requisicaoSelected"
                               class="check-table"
                               :value="item"
                               @change="($event) => $event.preventDefault()"
                               :id="'requisicao' + item.Id">
                        <label :for="'requisicao' + item.Id"></label>
                      </div>
                    </td>
                    <td>{{item.IdIntegracao || item.Id}}</td>
                    <td>{{item.Descricao}}</td>
                    <td>{{item.Classificacao}}</td>
                    <td>{{item.Tipo}}</td>
                    <td>{{item.ValorDisplay}}</td>
                    <td><span :class="'badge badge-' + resolveStatusRequisicaoClass(item.Status)">{{item.Status}}</span></td>
                    <td>{{item.Data}}</td>
                    <td>{{item.DataSituacaoDisplay}}</td>
                    <td>{{item.UsuarioRequisicaoDisplay}}</td>
                    <td>{{item.UsuarioAprovacaoDisplay}}</td>
                    <td>{{item.FornecedorDisplay}}</td>
                  </tr>

                </template>
              </tbody>
            </table>
            <h4 class="text-center"
                v-if="!result.Records.filter(f=>f.Id == selected[0])[0].Requisicao.length">
              {{'Veiculo '+result.Records.filter(f=>f.Id == selected[0])[0].Veiculo+' não possui nenhuma despesa cadastrada.'  }}<br />Clique no botão <b>+Despesa</b> para adicionar.
            </h4>
          </div>
        </template>

      </data-table>

    </div>
  </section>
</template>

<script>
// @ is an alias to /src
import { clienteEmpty } from '@/models/index.js';
import PessoaPartial from '@/components/PessoaPartial.vue';
import _consultar from '@/services/consultar.js';
import api from '@/services/api.js';
import AvaliacaoDetalhes from "@/views/AvaliacaoDetalhes.vue";
import { resolveStatusRequisicaoClass } from '@/services/helpers.js';

export default {
    components: {
        ..._consultar.components,
        'pessoa-partial': PessoaPartial,
        'avaliacao-detalhes-partial': AvaliacaoDetalhes
    },
    data() {
        const that = this;
        return {
            ..._consultar.data(),
            url: '/veiculos/index',
            configs: {},
            modals: { main: false, details: false, vendido: false, enviar: false, valor: false, solicita: false, encerra: false, sync: false,fornecedor:false,avaliacao: false },
            filtros: {
                data: [
                    {
                        id: 'placa',
                        title: 'Placa',
                        active: false,
                        placeholder: 'AAA-1111',
                        value: ''
                    },
                    {
                        id: 'avaliacao',
                        title: 'Avaliação',
                        active: false,
                        type: 'number'
                    },
                    {
                        id: 'requisicao',
                        title: 'Requisição',
                        active: false,
                        type: 'number'
                    },
                    {
                        id: 'StatusVeiculo',
                        title: 'Status Veículo',
                        active: false,
                        value: [],
                        source: [
                            //{
                            //    id: 'StatusVeiculo',
                            //    title: 'Pendente',
                            //    value: 0
                            //},
                            {
                                id: 'StatusVeiculo',
                                title: 'Preparo',
                                value: 1
                            },
                            {
                                id: 'StatusVeiculo',
                                title: 'Estoque',
                                value: 2
                            },
                            {
                                id: 'StatusVeiculo',
                                title: 'Vendido',
                                value: 3
                            },
                        ]

                    },
                    {
                        id: 'StatusRequisicao',
                        title: 'Status Requisição',
                        active: false,
                        source: [{
                            id: 'StatusRequisicao',
                            title: 'Orçamento',
                            value: 0
                        },
                        {
                            id: 'StatusRequisicao',
                            title: 'Pendente',
                            value: 1
                        },
                        {
                            id: 'StatusRequisicao',
                            title: 'Reprovado',
                            value: 2
                        },
                        {
                            id: 'StatusRequisicao',
                            title: 'Aprovado',
                            value: 3
                        },
                        {
                            id: 'StatusRequisicao',
                            title: 'Enviado',
                            value: 4
                        },
                        {
                            id: 'StatusRequisicao',
                            title: 'Executado',
                            value: 5
                        },
                        {
                            id: 'StatusRequisicao',
                            title: 'Encerrado',
                            value: 6
                        }
                        ],
                        value: []

                    },
                    {
                        id: 'TipoServico',
                        title: 'Tipo Serviço',
                        active: false,
                        value: [],
                        source: null,//tipoRequisicaoFiltro
                },
                    {
                        id: 'Classificacao',
                        title: 'Classificação Requisição',
                        active: false,
                        source: [{
                            id: 'Classificacao',
                            title: 'Mecânica',
                            value: 0
                        },
                        {
                            id: 'Classificacao',
                            title: 'Estética',
                            value: 1
                        },
                        {
                            id: 'Classificacao',
                            title: 'Revisão',
                            value: 2
                        },
                        {
                            id: 'Classificacao',
                            title: 'Outros',
                            value: 3
                        },
                        ],
                        value: []

                    },
                    {
                        id: 'DataCadastro',
                        title: 'Data Cadastro',
                        active: false,
                        type: 'date'
                    },
                    {
                        id: 'DataVenda',
                        title: 'Data Venda',
                        active: false,
                        type: 'date'
                    },
                    {
                        id: 'idIntegracaoAvaliacao',
                        title: 'Cód. Integração Avaliação',
                        active: false,
                        type: 'text'
                    },
                    {
                        id: 'idIntegracaoEstoque',
                        title: 'Cód. Integração Estoque',
                        active: false,
                        type: 'text'
                    },
                    {
                        id: 'idIntegracaoRequisicao',
                        title: 'Cód. Integração Despesa',
                        active: false,
                        type: 'text'
                    },
                    {
                        id: 'inconsistencia',
                        title: 'Com Inconsistências?',
                        active: false,
                        value: 'true',
                        source: [
                            {
                                id: 'inconsistencia',
                                title: 'Sim',
                                value: 'true'
                            },
                            {
                                id: 'inconsistencia',
                                title: 'Não',
                                value: 'false'
                            }
                        ],
                        sourceType: 'radio'
                    },
                    {
                        id: 'nf',
                        title: 'Nota Fiscal',
                        active: false,
                        type: 'number'
                    },
                ],
                visao: [
                    {
                        id: 4,
                        title: 'Visão padrão',
                        filtro: [
                            {
                                id: 'DataVenda',
                                valuede: '',//vendaDe,
                                valueate: ''
                            }
                        ]
                    },
                    {
                        id: 0,
                        title: 'Todos veículos',
                        filtro: []
                    },
                    {
                        id: 1,
                        title: 'Veículos em andamento',
                        filtro: [{
                            id: 'StatusVeiculo',
                            value: [1]
                        },
                        {
                            id: 'StatusRequisicao',
                            value: [0, 1, 3, 4]
                        },
                        ]
                    },
                    {
                        id: 2,
                        title: 'Veículos em garantia',
                        filtro: [
                            {
                                id: 'StatusVeiculo',
                                value: [3]
                            },
                            {
                                id: 'DataVenda',
                                valuede: '',//vendaDe,
                                valueate: ''//vendaAte
                            }
                        ]
                    },
                    {
                        id: 3,
                        title: 'Veículos em estoque',
                        filtro: [
                            {
                                id: 'StatusVeiculo',
                                value: [2]
                            },

                        ]
                    },
                    {
                        id: 5,
                        title: 'Requisições á encerrar',
                        filtro: [
                            {
                                id: 'StatusRequisicao',
                                value: [5]
                            },
                        ]
                    },

                ],
                visaoSelecionada: 4,
                visible: true
            },
            bkp: '',
            actionButtons: [

                //requisicoes
                {
                    text: 'Alterar Valor',
                    className: 'requisicao-valor',
                    icon: 'fas fa-dollar-sign',
                    color: 'primary',
                    visible: true,
                    title: 'Alterar valor da requisição',
                    order: 999,
                    callback: function () {
                        that.openValorModal();
                    }
                },
                {
                    text: 'Encerramento',
                    className: 'requisicao-executados',
                    icon: 'far fa-folder',
                    color: 'primary',
                    visible: true,
                    title: 'Lançar notas, executar, encerrar despesa',
                    order: 4,
                    callback: function () {
                      api.get('/requisicoes/lancamentos?id=' + that.requisicao.requisicaoSelected[0].Id)
                        .then(response => {
                          
                          that.requisicao.requisicaoSelected[0].NotaFiscal = response.data;
                          that.modals.encerra = true;

                        });
                    }
                },
                {
                    text: 'Retornar',
                    className: 'requisicao-retornar',
                    icon: 'fas fa-map-marker-alt',
                    color: 'primary',
                    visible: true,
                    title: `Alterar a localização do veículo para "${this.$root.user.EmpresaNome}"`,
                    order: 998,
                    callback: function () {
                        that.handleButtonRetornar();
                    }
                },
                {
                    text: 'Enviar',
                    className: 'requisicao-enviar',
                    icon: 'fas fa-print',
                    color: 'primary',
                    visible: true,
                    title: 'Enviar veículo para fornecedor',
                    order: 4,
                    callback: function () {
                        that.openEnviarModal();
                    }
                },
                {
                    text: 'Aprovar',
                    className: 'requisicao-aprovar',
                    icon: 'far fa-thumbs-up',
                    color: 'primary',
                    visible: true,
                    title: 'Aprovar despesa',
                    order: 3,
                    callback: function () {
                        that.handleButtonAprovar();
                    }
                },
                {
                    text: 'Reprovar',
                    className: 'requisicao-reprovar',
                    icon: 'far fa-thumbs-down',
                    color: 'primary',
                    visible: true,
                    title: 'Reprovar despesa',
                    order: 4,
                    callback: function () {
                        that.handleButtonReprovar();
                    }
                },
                {
                    text: 'Solicitar',
                    className: 'requisicao-solicitar-aprv',
                    icon: 'far fa-envelope',
                    color: 'primary',
                    visible: true, // inicial
                    visibleOnRowSelected: false,
                    title: 'Solicitar aprovação',
                    order: 997,
                    callback: function () {
                        that.openSolicitaModal();
                    }
                },

                //Veiculo
                {
                    id: 'devolvido',
                    text: 'Estornar',
                    className: 'veiculo-vendido',
                    icon: 'fas fa-undo',
                    color: 'default',
                    visible: false,
                    title: 'Retornar veículo de vendido(VE) para o estoque(ES).',
                    callback: function () {
                        that.handleButtonDevolver();
                    }
                },
                {
                    id: 'devolucao',
                    text: 'Devolução',
                    className: 'veiculo-vendido',
                    icon: 'fas fa-undo',
                    color: 'default',
                    visible: false,
                    title: 'Devolver veículo ao proprietário.',
                    callback: function () {
                        that.handleButtonDevolucao();
                    }
                },
                {
                    id: 'vendido',
                    text: 'Vendido',
                    className: 'veiculo-vendido',
                    icon: 'fas fa-flag',
                    color: 'default',
                    visible: false,
                    title: 'Marcar veículo como vendido.',
                    callback: function () {
                        that.modals.vendido = true;
                    }
                },
                {
                    id: 'pronto',
                    text: 'Pronto',
                    className: 'veiculo-pronto',
                    icon: 'fas fa-check',
                    color: 'default',
                    visible: false,
                    title: 'Enviar veículo ao estoque.',
                    callback: function () {
                        that.handleButtonPronto();
                    }
                },
                {
                    id: 'devolver',
                    text: 'Preparo',
                    className: 'veiculo-devolver',
                    icon: 'fas fa-wrench',
                    color: 'default',
                    visible: false,
                    title: 'Retornar veículo do estoque(ES) para em preparo(EP). Ao marcar esta opção, o veículo estará habilitado para fazer adicionar mais despesas.',
                    callback: function () {
                        that.handleButtonRetornarPreparo();
                    }
                },
                {
                    text: 'Despesa',
                    className: 'veiculo-novo-despesa',
                    icon: 'fas fa-plus',
                    color: 'default',
                    visible: false,
                    title: 'Incluir nova despesa',
                    callback: function () {
                        that.handleButtonNovaDespesa();
                    }
                },
                {
                    text: 'Detalhes',
                    className: 'veiculo-detalhes',
                    icon: 'fas fa-eye',
                    color: 'default',
                    visible: false, // inicial
                    visibleOnRowSelected: true,
                    title: 'Detalhes do veículo',
                    callback: function () {
                        that.handleButtonDetalhes();
                    }
                },
                {
                    text: 'Editar',
                    className: 'veiculo-detalhes',
                    icon: 'fas fa-edit',
                    color: 'default',
                    visible: true, // inicial
                    visibleOnRowSelected: true,
                    title: 'Editar veículo',
                    callback: function () {
                        that.$router.push('/veiculos/edit/'+that.itemSelecionado[0].Id);
                    }
                },
                {
                    id: 'create',
                    text: '',
                    className: 'create padrao',
                    icon: 'fas fa-plus',
                    color: 'default',
                    visible: true,
                    title: 'Criar novo veículo',
                    callback: function () {
                        that.handleNovoButton();
                    }
                },
                //Default
                {
                    id: 'excel',
                    text: '',
                    className: 'excel padrao',
                    icon: 'fas fa-file-excel',
                    iconColor: 'green',
                    color: 'default',
                    visible: true,
                    title: 'Exportar para excel',
                    callback: that.exportar
                },
                {
                    id: 'print',
                    text: '',
                    className: 'print padrao',
                    icon: 'fas fa-file-pdf',
                    iconColor: 'blue',
                    color: 'default',
                    visible: true,
                    title: 'Imprimir',
                    callback: that.print
                }
                
            ],
            params: {
                page: 1, //pag inicial
                order: 'Id',
                direction: 'desc',
                perPage: 10, //qtd registros por paginas
            },

            footerContent: '',
            requisicao: {
                bkp: '',
                fornecedor: [],
                servicos: [],//servicos,
                estados: [],//estados,
                tipoRequisicao: null,//tipoRequisicao,
                requisicao: {},
                cidades: [],
                historico: [],
                consulta: {},
                obj: {},
                totalEstoque: {
                    preparo: 0,
                    garantia: 0,
                    pendente: 0,
                    aprovado: 0,
                    enviado: 0,
                    executado: 0,
                    encerrado: 0,
                    preparoAvaliacao: 0,
                    total: 0,
                    saldo: 0

                },
                requisicaoSelected: [],
                files: [],
                Arquivos: '',
                usuarios: [],

            },
            avaliacao: []
        };
    },
    methods: {
        ..._consultar.methods,
        //Metodos padroes
        
        openEnviarModal: function () {
            let requisicao = this.requisicao.requisicaoSelected[0];
            requisicao.AlterarLocalizacao = true;
            requisicao.Imprimir = true;

            this.modals.enviar = true;
        },
        openValorModal: function () {
            this.modals.valor = true;
            this.requisicao.bkp = this.requisicao.requisicaoSelected[0].Valor;
        },
        openSolicitaModal: function () {
            this.requisicao.usuarios = [];
            let that = this;
            api
                .get('/requisicoes/solicitaraprovacao?id=' + this.requisicao.requisicaoSelected[0].Id)
                .then(response => {
                    that.requisicao.usuarios = response.data;
                    that.modals.solicita = true;
                });
        },
        
        closeSyncModal: function () {
            this.modals.sync = false;
        },
        closeSolicitaModal: function () {
            this.modals.solicita = false;
        },
        closeEncerraModal: function () {
            this.modals.encerra = false;
        },
        closeValorModal: function () {
            this.modals.valor = false;
        },
        closeMainModal: function () {
            this.modals.main = false;
        },
        closeSecondaryModal: function () {
            this.modals.secondary = false;
        },
        closeDetailsModal: function () {
            this.modals.details = false;
        },
        closeVendidoModal: function () {
            this.modals.vendido = false;
        },
        closeEnviarModal: function () {
            this.modals.enviar = false;
        },
        closeAvaliacaoModal: function () {
            this.modals.avaliacao = false;
        },

        
      callback: function (response) {
        if (response.data.More) {
          this.footerContent =
                `<table class="table table-sm">
                    <tbody>
                        <tr>
                            <td><b>Preparo:</b> ${response.data.More.preparo}</td>
                            <td><b>Estoque:</b> ${response.data.More.estoque}</td>
                            <td><b>Vendido:</b> ${response.data.More.vendido}</td>
                        </tr>
                    </tbody>
                </table>`;
        }
            
        },
        //Metodos padroes

        //******Ações botões estoque*******
      handleNovoButton: function () {
        this.$router.push('/veiculos/create');
      },
        handleButtonPronto: function () {
            let estoque = this.itemSelecionado[0];
            let that = this;

            bootbox.confirm({
                backdrop: true,
                message: 'Deseja realmente enviar este veículo ao Estoque?',
                locale: 'pt-BR',
                buttons: {
                    cancel: {
                        label: '<i class="fa fa-times"></i> Cancelar',
                        className: 'btn btn-sm btn-default'
                    },
                    confirm: {
                        label: '<i class="fa fa-check"></i> OK',
                        className: 'btn btn-sm btn-primary pull-left'
                    }
                },
                callback: function (result) {
                    let url;
                    function prontu() {
                        api
                            .post(url, estoque)
                            .then(response => {
                                if (response.data.success) {
                                    //se encontrei insiro a nova requisicao no item
                                    let index = that.result.Records.findIndex(o => o.Id === estoque.Id);
                                    that.result.Records.splice(index, 1, response.data.obj);
                                    that.changeCheckboxCallback(that.result.Records[index]);

                                }
                            })
                            
                    }
                    if (result) {

                        if (estoque.Requisicao.length === 0 || !estoque.Requisicao.some(r => r.StatusRequisicao !== 6 && r.StatusRequisicao !== 2)) {
                            url = '/veiculos/pronto';
                            prontu();
                        }
                        else {
                            url = '/veiculos/prontorequisicoesabertas';
                            bootbox.confirm({
                                message: 'Se seu usuário não tiver a permissão de marcar como pronto os veículos com requisições abertas, não será possivel concluir esta ação.',
                                locale: 'pt-BR',
                                title: 'Este veículo possui requisições abertas',
                                buttons: {
                                    cancel: {
                                        label: '<i class="fa fa-times"></i> Cancelar',
                                        className: 'btn btn-sm btn-default'
                                    },
                                    confirm: {
                                        label: '<i class="fa fa-check"></i> OK',
                                        className: 'btn btn-sm btn-primary pull-left'
                                    }
                                },
                                callback: function (result) {
                                    if (result) {
                                        prontu();
                                    }
                                }
                            });
                        }

                    }
                }
            });
        },
        handleButtonVendido: function () {
            let estoque = this.itemSelecionado[0];
            this.$root.errors = [];
            let that = this;
            api
                .post('/veiculos/vendido', estoque)
                .then(response => {
                    if (response.data.success) {
                        //se encontrei insiro a nova requisicao no item
                        let index = that.result.Records.findIndex(o => o.Id === estoque.Id);
                        that.result.Records.splice(index, 1, response.data.obj);
                        that.changeCheckboxCallback(that.result.Records[index]);
                        that.modals.vendido = false;
                    } else {
                        that.$root.errors = response.data.errors;
                    }
                });
        },
        handleButtonDevolucao: function () {
            let estoque = this.itemSelecionado[0];
            let that = this;
            bootbox.confirm({
                backdrop: true,
                message: 'Deseja realmente retirar este veículo do estoque,faendo a devolução ao antigo proprietário?',
                locale: 'pt-BR',
                buttons: {
                    cancel: {
                        label: '<i class="fa fa-times"></i> Cancelar',
                        className: 'btn btn-sm btn-default'
                    },
                    confirm: {
                        label: '<i class="fa fa-check"></i> OK',
                        className: 'btn btn-sm btn-primary pull-left'
                    }
                },
                callback: function (result) {
                    if (result) {
                        api
                            .post('/veiculos/devolucao', estoque)
                            .then(response => {
                                if (response.data.success) {
                                    //se encontrei insiro a nova requisicao no item
                                    let index = that.result.Records.findIndex(o => o.Id === estoque.Id);
                                    that.result.Records.splice(index, 1, response.data.obj);
                                    that.changeCheckboxCallback(that.result.Records[index]);

                                } else {
                                    that.$root.errors = response.data.errors;
                                }
                            });
                    }
                }
            });
        },
        handleButtonDevolver: function () {
            let estoque = this.itemSelecionado[0];
            let that = this;
            bootbox.confirm({
                backdrop: true,
                message: 'Deseja realmente retornar este veículo ao Estoque?',
                locale: 'pt-BR',
                buttons: {
                    cancel: {
                        label: '<i class="fa fa-times"></i> Cancelar',
                        className: 'btn btn-sm btn-default'
                    },
                    confirm: {
                        label: '<i class="fa fa-check"></i> OK',
                        className: 'btn btn-sm btn-primary pull-left'
                    }
                },
                callback: function (result) {
                    if (result) {
                        api
                            .post('/veiculos/devolvido', estoque)
                            .then(response => {
                                if (response.data.success) {
                                    //se encontrei insiro a nova requisicao no item
                                    let index = that.result.Records.findIndex(o => o.Id === estoque.Id);
                                    that.result.Records.splice(index, 1, response.data.obj);
                                    that.changeCheckboxCallback(that.result.Records[index]);

                                } else {
                                    that.$root.errors = response.data.errors;
                                }
                            });
                    }
                }
            });
        },
        closeFornecedorModal: function () {
            this.modals.fornecedor = false;
        },
        handleButtonNovaDespesa: function () {
            let that = this;
            if (this.itemSelecionado[0].TipoEstoque === 0 &&
                this.itemSelecionado[0].IdIntegracaoAvaliacao === null &&
                this.itemSelecionado[0].IdIntegracao !== null) {

                api.get(`/avaliacoes/index?page=1&placa=${this.itemSelecionado[0].Placa}&status=1`)
                    .then(response => {
                        that.avaliacao = response.data.Records;
                        this.modals.avaliacao = true;

                    });

            }
            else {
                this.requisicao.requisicao = {
                    DepartamentoId: null,
                    EstoqueId: this.itemSelecionado[0].Id,
                    Valor: 0,
                    TipoRequisicaoId: null,
                    ClassificacaoServico: null,
                    Descricao: '',
                    Servico: {
                      Descricao: '',
                      ClassificacaoServico: null,
                    }
                };
                this.requisicao.obj = clienteEmpty();
                
                let diasGarantia = this.$root.user.EmpresaDiasGarantia;
                let dt = this.itemSelecionado[0].DVenda;
                let diasVenda = dt && (parseInt((new Date() - new Date(dt.substr(0, dt.indexOf('T')).split('-'))) / (24 * 3600 * 1000)));

                if (dt && diasVenda > diasGarantia) {
                    for (let i in this.configs.tipoRequisicao) {

                        this.configs.tipoRequisicao[i].Ativo = false;

                        this.configs.tipoRequisicao[i].Ativo =
                            this.configs.tipoRequisicao[i].RegraStatus.some(f => f.StatusEstoque === this.itemSelecionado[0].StatusEstoque && (f.PosGarantia && dt && (diasVenda > diasGarantia)));

                    }
                }
                else {
                    for (let i in this.configs.tipoRequisicao) {

                        this.configs.tipoRequisicao[i].Ativo = true;

                        this.configs.tipoRequisicao[i].Ativo =
                            this.configs.tipoRequisicao[i].RegraStatus.some(f => f.StatusEstoque === this.itemSelecionado[0].StatusEstoque && (!f.PosGarantia || (f.PosGarantia && dt && (diasVenda > diasGarantia))));

                    }
                }

                var novoId = this.configs.tipoRequisicao.filter(tr => tr.Ativo).map(tr => tr.Id)[0];

                this.requisicao.requisicao.TipoRequisicaoId = novoId;
                this.requisicao.historico = [];

                this.modals.main = true;
            }
        },
        handleButtonDetalhes: function () {
            this.modals.details = true;
        },
        handleButtonRetornarPreparo: function () {
            let estoque = this.itemSelecionado[0];
            let that = this;

            bootbox.confirm({
                backdrop: true,
                message: 'Deseja realmente retornar este veículo do estoque para preparo?',
                locale: 'pt-BR',
                buttons: {
                    cancel: {
                        label: '<i class="fa fa-times"></i> Cancelar',
                        className: 'btn btn-sm btn-default'
                    },
                    confirm: {
                        label: '<i class="fa fa-check"></i> OK',
                        className: 'btn btn-sm btn-primary pull-left'
                    }
                },
                callback: function (result) {
                    if (result) {
                        api
                            .post('/veiculos/retornapreparo', estoque)
                            .then(response => {
                                if (response.data.success) {
                                    //se encontrei insiro a nova requisicao no item
                                    let index = that.result.Records.findIndex(o => o.Id === estoque.Id);
                                    that.result.Records.splice(index, 1, response.data.obj);
                                    that.changeCheckboxCallback(that.result.Records[index]);

                                }
                            })
                    }
                }
            });
        },
        handleButtonAssociar: function (id) {
            let that = this;

            bootbox.confirm({
                backdrop: true,
                message: 'Deseja associar a avaliação '+id+' ?',
                locale: 'pt-BR',
                buttons: {
                    cancel: {
                        label: '<i class="fa fa-times"></i> Cancelar',
                        className: 'btn btn-sm btn-default'
                    },
                    confirm: {
                        label: '<i class="fa fa-check"></i> OK',
                        className: 'btn btn-sm btn-primary pull-left'
                    }
                },
                callback: function (result) {
                    if (result) {
                        api.post('/veiculos/associaravaliacao', { Id: that.itemSelecionado[0].Id, AvaliacaoId: id })
                            .then(response => {
                                if (response.data.success) {
                                    that.itemSelecionado[0].IdIntegracaoAvaliacao = id;
                                    that.modals.avaliacao = false;
                                }
                            });
                    }
                }
            });

           
        },

        //******Ações botões estoque*******

        //******Ações botões requisicao*******

        handleButtonEnviar: function () {
            let estoque = this.itemSelecionado[0];
            let requisicao = this.requisicao.requisicaoSelected[0];
            let that = this;
            requisicao.Placa = estoque.Placa;
            api
                .post('/requisicoes/enviar', requisicao)
                .then(response => {
                    if (response.data.success) {
                        //se encontrei insiro a nova requisicao no item
                        if (estoque) {
                            estoque.Requisicao.splice(estoque.Requisicao.indexOf(requisicao), 1, response.data.obj);

                            that.handleRowClickRequisicao(response.data.obj);
                            that.calcularRequisicao(estoque);
                            if (requisicao.AlterarLocalizacao) {
                                estoque.Localizacao = requisicao.FornecedorDisplay;
                            }
                        }
                        that.modals.enviar = false;
                      if (requisicao.Imprimir) {

                        

                        api.get('/requisicoes/imprimir?id=' + requisicao.Id)
                          .then(response => {
                            localStorage.setItem('ea-excel', null);
                            localStorage.setItem('ea-excel', JSON.stringify(response.data));
                            window.open('/templates/requisicao.html?id=' + requisicao.Id, '_blank');
                        });
                    }
                }
            });
        },
        handleButtonRetornar: function () {
            let estoque = this.itemSelecionado[0];
            let requisicao = this.requisicao.requisicaoSelected[0];
            let that = this;

            bootbox.confirm({
                backdrop: true,
                message: `Deseja alterar a localização do veículo para "${this.$root.user.EmpresaNome}"`,
                locale: 'pt-BR',
                buttons: {
                    confirm: {
                        label: '<i class="fa fa-check"></i> OK',
                        className: 'btn btn-sm btn-primary'
                    },
                    cancel: {
                        label: '<i class="fa fa-times"></i> Cancelar',
                        className: 'btn btn-sm btn-default pull-left'
                    }
                },
                callback: function (result) {
                    if (result) {
                        api
                            .post('/requisicoes/retornar', requisicao)
                            .then(response => {
                                if (response.data.success) {
                                    //se encontrei insiro a nova requisicao no item
                                    if (estoque) {
                                        let requisicoes = estoque.Requisicao;
                                        requisicoes.splice(requisicoes.indexOf(requisicao), 1, response.data.obj);
                                        estoque.Localizacao = `${that.$root.user.EmpresaNome}`;
                                        that.handleRowClickRequisicao(response.data.obj);
                                        that.calcularRequisicao(estoque);
                                    }
                                }
                            });
                    }
                }
            });
        },
        handleButtonReprovar: function () {
            let estoque = this.itemSelecionado[0];
            let requisicao = this.requisicao.requisicaoSelected[0];
            let that = this;

            bootbox.confirm({
                backdrop: true,
                message: 'Deseja realmente REPROVAR esta solicitação?',
                locale: 'pt-BR',
                buttons: {
                    cancel: {
                        label: '<i class="fa fa-times"></i> Cancelar',
                        className: 'btn btn-sm btn-default'
                    },
                    confirm: {
                        label: '<i class="fa fa-check"></i> OK',
                        className: 'btn btn-sm btn-primary pull-left'
                    }
                },
                callback: function (result) {
                    if (result) {
                        api
                            .post('/requisicoes/reprovar', requisicao)
                            .then(response => {
                                if (response.data.success) {
                                    //se encontrei insiro a nova requisicao no item
                                    if (estoque) {
                                        estoque.Requisicao.splice(estoque.Requisicao.indexOf(requisicao), 1, response.data.obj);

                                        that.handleRowClickRequisicao(response.data.obj);
                                        that.calcularRequisicao(estoque);
                                    }
                                }
                            });
                    }
                }
            });
        },
        handleButtonValor: function () {
            this.$root.errors = [];
            let that = this;
            api
                .post('/requisicoes/valor', this.requisicao.requisicaoSelected[0])
                .then(response => {
                    if (response.data.success) {

                        //busco id do item que retornou no array
                        let x = that.itemSelecionado[0];
                        //se encontrei insiro a nova requisicao no item
                        if (x) {
                            let y = x.Requisicao.filter(r => response.data.obj.Id === r.Id)[0];
                            x.Requisicao.splice(x.Requisicao.indexOf(y), 1, response.data.obj);

                            that.handleRowClickRequisicao(response.data.obj);
                            that.calcularRequisicao(x);
                        }
                        //se não encontrei, foda-se
                        that.modals.valor = false;
                    }
                });
        },
        handleButtonAprovar: function () {
            let estoque = this.itemSelecionado[0];
            let requisicao = this.requisicao.requisicaoSelected[0];
            let url = '/requisicoes/';
            let that = this;

            url += estoque.StatusEstoque === 4 ? 'aprovargarantia' : 'aprovar';
            bootbox.confirm({
                backdrop: true,
                message: 'Deseja realmente APROVAR esta solicitação?',
                locale: 'pt-BR',
                buttons: {
                    cancel: {
                        label: '<i class="fa fa-times"></i> Cancelar',
                        className: 'btn btn-sm btn-default'
                    },
                    confirm: {
                        label: '<i class="fa fa-check"></i> OK',
                        className: 'btn btn-sm btn-primary pull-left'
                    }
                },
                callback: function (result) {
                    if (result) {
                        api
                            .post(url, requisicao)
                            .then(response => {
                                if (response.data.success) {
                                    //se encontrei insiro a nova requisicao no item
                                    if (estoque) {
                                        let requisicoes = estoque.Requisicao;
                                        requisicoes.splice(requisicoes.indexOf(requisicao), 1, response.data.obj);

                                        that.handleRowClickRequisicao(response.data.obj);
                                        that.calcularRequisicao(estoque);
                                    }
                                }
                            });

                    }
                }
            });
        },
        handleButtonSolicita: function ({ requisicaoId, usuarioId, $event }) {
           
            api
                .post('/requisicoes/solicitaraprovacao', { id: requisicaoId, usuario: usuarioId })
                .then(response => {
                    if (response.data.success) {
                        $($event.target).remove();
                    }
                });
                
        },
        handleButtonEncerra: function () {
            let estoque = this.itemSelecionado[0];
            let requisicao = this.requisicao.requisicaoSelected[0];
            let that = this;

            api
                .post('/requisicoes/encerrar', requisicao)
                .then(response => {
                    if (response.data.success) {
                        //se encontrei insiro a nova requisicao no item
                        if (estoque) {
                            estoque.Requisicao.splice(estoque.Requisicao.indexOf(requisicao), 1, response.data.obj);

                            that.handleRowClickRequisicao(response.data.obj);
                            that.calcularRequisicao(estoque);
                            that.modals.encerra = false;
                        }
                    }
                });
        },
        handleButtonExecutar: function () {
            let estoque = this.itemSelecionado[0];
            let requisicao = this.requisicao.requisicaoSelected[0];
            let desc = requisicao.Valor > requisicao.TotalLancado;
            let that = this;

            function confirma() {
                bootbox.confirm({
                    backdrop: true,
                    message: 'Deseja realmente Executar esta requisição?',
                    locale: 'pt-BR',
                    buttons: {
                        cancel: {
                            label: '<i class="fa fa-times"></i> Cancelar',
                            className: 'btn btn-sm btn-default'
                        },
                        confirm: {
                            label: '<i class="fa fa-check"></i> OK',
                            className: 'btn btn-sm btn-primary pull-left'
                        }
                    },
                    callback: function (result) {
                        if (result) {
                            api
                                .post('/requisicoes/executar', requisicao)
                                .then(response => {
                                    if (response.data.success) {
                                        //se encontrei insiro a nova requisicao no item
                                        if (estoque) {
                                            estoque.Requisicao.splice(estoque.Requisicao.indexOf(requisicao), 1, response.data.obj);

                                            that.handleRowClickRequisicao(response.data.obj);
                                            that.calcularRequisicao(estoque);
                                        }
                                    }

                                });

                        }
                    }
                });
            }

            if (desc) {
        
                bootbox.confirm({

                    backdrop: true,
                    message: 'Existe um valor de saldo a ser baixado dentro da requisição, este valor será dado como desconto na requisição, você confirma esta situação ?',
                    locale: 'pt-BR',
                    buttons: {
                        cancel: {
                            label: '<i class="fa fa-times"></i> Cancelar',
                            className: 'btn btn-sm btn-default'
                        },
                        confirm: {
                            label: '<i class="fa fa-check"></i> OK',
                            className: 'btn btn-sm btn-primary pull-left'
                        }
                    },
                    callback: function (result) {
                        if (result) {
                           
                            confirma();
                        }
                    }
                });
            }
            else {
                confirma();
            }
            
        },
        handleButtonInconsistente: function () {
            let that = this;
            let estoque = this.itemSelecionado[0];
            let requisicao = this.requisicao.requisicaoSelected[0];

            bootbox.confirm({
            backdrop: true,
                message: `Deseja realmente marcar o lançamento da nota como inconsistente?`,
                locale: 'pt-BR',
                buttons: {
                    confirm: {
                        label: '<i class="fa fa-check"></i> OK',
                        className: 'btn btn-sm btn-primary'
                    },
                    cancel: {
                        label: '<i class="fa fa-times"></i> Cancelar',
                        className: 'btn btn-sm btn-default pull-left'
                    }
                },
                callback: function (result) {
                    if (result) {
                        var observacao = prompt('Digite a justificativa da inconsistência: ');
                        if (!observacao) {
                            return;
                        }

                        
                        requisicao.MotivoInconsistencia = observacao;
                        api
                            .post('/requisicoes/inconsistente', requisicao)
                            .then(response => {
                                if (response.data.success) {
                                    //se encontrei insiro a nova requisicao no item
                                    if (estoque) {
                                        estoque.Requisicao.splice(estoque.Requisicao.indexOf(requisicao), 1, response.data.obj);

                                        that.handleRowClickRequisicao(response.data.obj);
                                        that.calcularRequisicao(estoque);
                                        that.modals.encerra = false;
                                    }
                                }
                            });
                            
                    }
                }
            });
        },
        handleButtonLancamento: function (nota) {
            let estoque = this.itemSelecionado[0];
            let requisicao = this.requisicao.requisicaoSelected[0];
            this.$root.errors = [];
            let that = this;

            if (files.files.length) {
                let formData = new FormData();
                for (var i = 0; i < files.files.length; i++) {
                    let file = files.files[i];
                    if (file.size) {
                        formData.append('files[' + i + ']', file);
                    }
                }

                const descricao = JSON.stringify(nota);

                formData.append('id', estoque.Id);
                formData.append('nota', descricao);

                api
                    .post('/requisicoes/lancarnota', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(response => {
                        if (response.data.success) {
                            //se encontrei insiro a nova requisicao no item
                            if (estoque) {
                                that.requisicao.Arquivos = '';
                                estoque.Requisicao.splice(estoque.Requisicao.indexOf(requisicao), 1, response.data.obj);
                                this.handleRowClickRequisicao(response.data.obj);
                                this.calcularRequisicao(estoque);
                            }
                            that.requisicao.Arquivos = '';

                        }

                    });
            } else {
                displayMessage({
                    type: 'error',
                    msg: `Nenhum arquivo selecionado.`
                });
            }
        },
        handleButtonExcluirLancamento: function (nota) {
            let that = this;
            let estoque = this.itemSelecionado[0];
            let requisicao = this.requisicao.requisicaoSelected[0];

            bootbox.confirm({
                backdrop: true,
                message: `Confirma exclusão da nota de ${nota.ValorDisplay}`,
                locale: 'pt-BR',
                buttons: {
                    cancel: {
                        label: '<i class="fa fa-times"></i> Cancelar',
                        className: 'btn btn-sm btn-default'
                    },
                    confirm: {
                        label: '<i class="fa fa-check"></i> OK',
                        className: 'btn btn-sm btn-primary pull-left'
                    }
                },
                callback: function (result) {
                    if (result) {
                        

                        nota.RequisicaoId = requisicao.Id;
                        api
                            .post('/requisicoes/excluirnota', nota)
                            .then(response => {
                                if (response.data.success) {
                                    //se encontrei insiro a nova requisicao no item
                                    if (estoque) {
                                        estoque.Requisicao.splice(estoque.Requisicao.indexOf(requisicao), 1, response.data.obj);
                                        that.handleRowClickRequisicao(response.data.obj);
                                        that.calcularRequisicao(estoque);
                                    }
                                }
                            });
                    }
                }
            });
        },

        //******Ações botões requisicao*******

        //*******Helpers*******
        requisicaoTitleHelper: function (item) {
            return (item.MotivoInconsistencia ? 'Motivo inconsistência: ' + item.MotivoInconsistencia + '\n\n' : '') + (item.ObservacaoValor ? 'Observação do valor: ' + item.ObservacaoValor : '');
        },
        getTitle: function (item) {
            if (item.IsApto) {
                return "Veículo sem requisições em aberto. \nEstá pronto para ser enviado ao estoque.";
            }
            else {
                return 'Serviços da avaliação: \n\n' + item.ServicosAvaliacao.map(c => `${c.ValorDisplay.padEnd(14, '-')}${c.Descricao}`).join('\n') + `\n\n${item.TotalServicosAvaliador.padEnd(14, '-')}Total`;
            }
        },
        
        
        resolveCheckboxClass: function (item) {
            if (item.IsApto) {
                return 'warning';
            }
            if (item.IsNovo) {
                return 'danger';
            }
            return 'primary';
        },
        resolveStatusClass: function (name) {
            switch (name) {
                case 'PE':
                    return 'warning';
                case 'EP':
                    return 'info';
                case 'ES':
                    return 'success';
                case 'VE':
                    return 'muted';
                default: 
                    return 'dark';
            }
        },
        resolveStatusSLAClass: function (statusEstoque, status) {

            if (status === statusEstoque) {
                return 'warning';
            } else if (statusEstoque > status) {
                return 'success';
            } else if (statusEstoque < status) {
                return 'muted';
            } else {
                return 'dark';
            }
        },
        changeCheckboxCallback: function (item) {
          let that = this;
            this.selecionado = '';

            this.requisicao.totalEstoque.preparo
            this.requisicao.totalEstoque.garantia
            this.requisicao.totalEstoque.pendente
            this.requisicao.totalEstoque.aprovado
            this.requisicao.totalEstoque.enviado
            this.requisicao.totalEstoque.executado
            this.requisicao.totalEstoque.encerrado
            this.requisicao.totalEstoque.total
            this.requisicao.totalEstoque.saldo = 0;

            let btnDespesa = this.actionButtons.filter(b => b.text === 'Despesa')[0];
            let btnDetalhes = this.actionButtons.filter(b => b.text === 'Detalhes')[0];
            let btnPronto = this.actionButtons.filter(b => b.text === 'Pronto')[0];
            let btnVendido = this.actionButtons.filter(b => b.text === 'Vendido')[0];
            let btnPreparo = this.actionButtons.filter(b => b.text === 'Preparo')[0];
            let btnDevolvido = this.actionButtons.filter(b => b.text === 'Estornar')[0];
            let btnDevolucao = this.actionButtons.filter(b => b.text === 'Devolução')[0];


            btnDespesa.visible = false;
            btnDetalhes.visible = false;
            btnPronto.visible = false;
            btnVendido.visible = false;
            btnPreparo.visible = false;
            btnDevolucao.visible = false;
            btnDevolvido.visible = false;

            this.requisicao.requisicaoSelected.pop();
            if (item) {
                btnDetalhes.visible = true;
                btnDespesa.visible = true;
                this.selecionado = `${item.Placa} ${item.Modelo} selecionado`;
                switch (item.StatusEstoque) {
                    case 1: //em preparo
                        btnPronto.visible = true;
                        btnDevolucao.visible = true;

                        break;
                    case 2: //Estoque
                        btnPreparo.visible = true;
                        btnVendido.visible = true;
                        btnDevolucao.visible = true;                        

                        break;
                    case 3: //vendido
                        btnDevolvido.visible = true;
                        break;
                    case 4://devolvido
                        //btnDevolvido.visible = btnDespesa.visible = false;
                }
              api.get('/requisicoes/index?id=' + item.Id)
                .then(response => {
                  item.Requisicao = response.data;
                  that.$nextTick(() => {
                    this.calcularRequisicao(item);
                  })
                });

            }
        },
        resolveStatusRequisicaoClass: resolveStatusRequisicaoClass,
        calcularRequisicao: function (item) {

            let arrRequisicao = item.Requisicao;
            if (arrRequisicao && arrRequisicao.length) {
                var arr;
                arr = arrRequisicao.filter(f => !f.Tipo.includes('Garantia') && f.StatusRequisicao > 2);
                this.requisicao.totalEstoque.preparo = arr.length ? arr.map(c => c.Valor).reduce((partial_sum, a) => partial_sum + a) : 0;

                arr = arrRequisicao.filter(f => f.Tipo.includes('Garantia') && f.StatusRequisicao > 2);
                this.requisicao.totalEstoque.garantia = arr.length ? arr.map(c => c.Valor).reduce((partial_sum, a) => partial_sum + a) : 0;

                arr = arrRequisicao.filter(f => f.StatusRequisicao === 0 || f.StatusRequisicao === 1);
                this.requisicao.totalEstoque.pendente = arr.length ? arr.map(c => c.Valor).reduce((partial_sum, a) => partial_sum + a) : 0;

                arr = arrRequisicao.filter(f => f.StatusRequisicao === 3);
                this.requisicao.totalEstoque.aprovado = arr.length ? arr.map(c => c.Valor).reduce((partial_sum, a) => partial_sum + a) : 0;

                arr = arrRequisicao.filter(f => f.StatusRequisicao === 4);
                this.requisicao.totalEstoque.enviado = arr.length ? arr.map(c => c.Valor).reduce((partial_sum, a) => partial_sum + a) : 0;

                arr = arrRequisicao.filter(f => f.StatusRequisicao === 5);
                this.requisicao.totalEstoque.executado = arr.length ? arr.map(c => c.Valor).reduce((partial_sum, a) => partial_sum + a) : 0;

                arr = arrRequisicao.filter(f => f.StatusRequisicao === 6);
                this.requisicao.totalEstoque.encerrado = arr.length ? arr.map(c => c.Valor).reduce((partial_sum, a) => partial_sum + a) : 0;

                arr = arrRequisicao.filter(f => f.StatusRequisicao > 2);
                this.requisicao.totalEstoque.total = arr.length ? arr.map(c => c.Valor).reduce((partial_sum, a) => partial_sum + a) : 0;


                // this.requisicao.totalEstoque.saldo = valorAprovacao - this.requisicao.totalEstoque.total;

            }
        },
        //*******Helpers*******

        //*******modal requisicao*******
        handleRowClickRequisicao: function (item) {

            var toggle = this.requisicao.requisicaoSelected.length ? this.requisicao.requisicaoSelected[0] === item : false;
            this.requisicao.requisicaoSelected.pop();
            if (!toggle) {
                this.requisicao.requisicaoSelected.push(item);
            }
            const btns = this.actionButtons.filter(b => b.className.includes('requisicao'));
            if (this.requisicao.requisicaoSelected.length) {
                for (let i in btns) {
                    btns[i].visible = false;
                }

                let btnValor = btns.filter(b => b.text === 'Alterar Valor')[0];
                let btnEncerramento = btns.filter(b => b.text === 'Encerramento')[0];
                let btnRetorno = btns.filter(b => b.text === 'Retornar')[0];
                let btnEnviar = btns.filter(b => b.text === 'Enviar')[0];
                let btnAprovar = btns.filter(b => b.text === 'Aprovar')[0];
                let btnReprovar = btns.filter(b => b.text === 'Reprovar')[0];
                let btnSolicitar = btns.filter(b => b.text === 'Solicitar')[0];



                switch (item.StatusRequisicao) {
                    case 0: //Orcamento,
                        btnValor.visible = true;
                        btnEnviar.visible = true;
                        btnReprovar.visible = true;
                        btnRetorno.visible = true;
                        break;
                    case 1: //    Pendente,
                        btnAprovar.visible = true;
                        btnReprovar.visible = true;
                        btnSolicitar.visible = true;
                        btnValor.visible = true;
                        btnRetorno.visible = true;
                        break;
                    case 2: //    Reprovada,
                        btnValor.visible = true;
                        break;
                    case 3: //    Aprovada,
                        btnValor.visible = true;
                        btnReprovar.visible = true;
                        btnEnviar.visible = true;
                        btnRetorno.visible = true;
                        break;
                    case 4: //    Enviado,
                        btnRetorno.visible = true;
                        btnEncerramento.visible = true;
                        btnEnviar.visible = true;
                        btnValor.visible = true;
                        break;
                    case 5: //    Executado,
                        btnValor.visible = true;
                        btnEncerramento.visible = true;
                        btnRetorno.visible = true;
                        btnReprovar.visible = true;
                        break;
                    case 6: //    Encerrado
                        btnEncerramento.visible = true;
                        btnReprovar.visible = true;
                        break;
                    default:
                }
            }
        },
        buscarServico: function (search) {
            this.handleBuscarServico(search, this);
        },
        handleBuscarServico: _.debounce((search, that) => {
          if (search) {
            api.get(`/configuracoes/buscarservico?q=${escape(search)}`)
                .then(response => {
                    that.requisicao.servicos = response.data;
                })
          }
        }, 350),
        changeServico: async function (val) {
            this.requisicao.historico = [];
            const associa = (val) => {
                debugger;
                if(!val.Id) {
                    let search = servicos.filter(s => {
                        let term = s.Descricao.split('[Cód')[0].trim().toLowerCase();
                        return val.Descricao.trim().toLowerCase() === term;
                    })
                    debugger;
                    if(search.length) {
                       val = search[0];
                    }
                }
                
                if (!val.ClassificacaoServico) {
                    val.ClassificacaoServico = 0;
                }
                if (!val.Id) {
                    val.Id = 0
                }
                if (!val.ValorPadrao) {
                    val.ValorPadrao = 0;
                }
                requisicao.Servico = val;
                requisicao.Valor = val.ValorPadrao;
                requisicao.ClassificacaoServico = val.ClassificacaoServico;
                requisicao.Descricao = val.Descricao;
            }
            let requisicao = this.requisicao.requisicao;
            let servicos = this.requisicao.servicos;

            if (val) {
                if (typeof (val) === 'string') {
                    requisicao.Servico = {
                        Id: 0,
                        ClassificacaoServico: 0,
                        Descricao: val,
                        ValorPadrao: 0
                    };
                    requisicao.Valor = 0;
                } else {
                    associa(val);
                }

                const {Id} = requisicao.Servico;
                if (Id != 0) {
                    let response = await api.get('/parametros/requisicoes-por-servico?Id='+Id );

                    this.requisicao.historico = response.data

                }
            }
        },
        changeArquivo: function ($event) {
            
            let requisicao = this.requisicao;

            if ($event.target.files.length === 0) {
                requisicao.Arquivos = '';
            } else if ($event.target.files.length === 1) {
                requisicao.Arquivos = $event.target.files[0].name;
            } else {
                requisicao.Arquivos = $event.target.files.length + ' selecionados';
            }
        },
        salvarRequisicao: function () {
            let requisicao = this.requisicao.requisicao;
            let formData = new FormData();
            let that = this;

            this.$root.errors = [];

            if (files.files.length) {

                for (var i = 0; i < files.files.length; i++) {
                    let file = files.files[i];
                    if (file.size) {
                        formData.append('Files[' + i + ']', file);
                    }
                }
            }
            let json = JSON.stringify(requisicao);
            formData.append('requisicao', json);


            api
                .post('/requisicoes/salvar', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                  if (response.data.success) {
                    that.requisicao.servicos = [];
                    that.requisicao.fornecedor = [];

                        that.requisicao.requisicaoSelected.pop();

                        //busco id do item que retornou no array
                        let x = that.itemSelecionado[0];
                        //se encontrei insiro a nova requisicao no item
                        if (x) {
                            x.Requisicao.splice(0,0,response.data.obj);
                            that.handleRowClickRequisicao(response.data.obj);

                            x.QtdPenOrc++;
                            that.calcularRequisicao(x);
                        }

                        requisicao = {};
                        that.requisicao.Arquivos = '';
                        that.modals.main = false;

                    }
                });
        },

        OnSearch: function (search) {

            this.searchFornecedor(search, this);
        },
        searchFornecedor: _.debounce((search, that) => {
          if (search) {
            api.get(`/fornecedores/buscar?q=${escape(search)}`)
                .then(response => {
                    that.requisicao.fornecedor = response.data;
                })
          }
        }, 350),
        abrirModalFornecedor: function () {
            this.requisicao.consulta = {
                nome: '',
                cpfCnpj: '',
                resultado: []
            };
            this.requisicao.obj = clienteEmpty();
            this.$root.errors = [];
            this.modals.fornecedor = true;

        },
        salvarFornecedor: function () {
            let that = this;
            this.$root.errors = [];
            api
                .post('/fornecedores/index', this.requisicao.obj.Cliente)
                .then(response => {
                    if (response.data.success) {
                        that.requisicao.fornecedor = [response.data.obj];
                        that.requisicao.requisicao.Fornecedor = response.data.obj;
                        that.abrirModalFornecedor();
                    }
                });
        }
        
    },
    mounted: function () {
        this.$nextTick(function () {
            let date = new Date();
            date.setDate(new Date().getDate() - this.$root.user.EmpresaDiasGarantia);
            let visaoPadrao = this.filtros.visao.filter(f => f.id === 4)[0].filtro[0];
            visaoPadrao.valuede = date.toISOString().split('T')[0];

            api.get('/veiculos/carregarconfigs')
                .then(response => {
                    this.configs = response.data;


                    let tpServico = this.filtros.data.filter(f => f.id === 'TipoServico')[0];
                    tpServico.source = this.configs.tipoRequisicaoFiltro;
                    this.requisicao.tipoRequisicao = this.configs.tipoRequisicao.sort((a, b) => a.Id - b.Id);

                    this.handleTrocarVisao();
                });

        });
    }
};
</script>

<style scoped>
  
.h-35 {
    height: 35%!important
}

.tr-head-requisicao {
    background: #0088cc;
    color: #fff;
}

.card-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.item-sla {
    cursor: pointer;
    padding: 0;
    margin: 0
}

blockquote.muted {
    border-color: #c7c7c7;
}

.card-title {
    font-size: 18px;
    text-overflow: ellipsis;
}


input[type="file"] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.file-description {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 140px;
    display: inline-block;
    vertical-align: sub;
}

.table-nota .btn i {
    color: #fff;
}
  
  @media (max-width: 992px) {
      .legenda-status{
          text-align:center
      }
  }
</style>
